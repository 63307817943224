import "./LeaderboardPage.css"
import MiniTeamViewer from "./MiniTeamViewer";

function Leaderboard(props){
    const pointsLabel = props.pointsLabel || "points";
    const allUserPoints = props.userPoints
    return(
        <table className = "leaderboard-table">
        <thead>
            <tr><th width="10%">Rank</th><th width="70%">Username</th><th width="20%">{pointsLabel}</th></tr>
        </thead>
        <tbody>
        {
            allUserPoints
            .filter(u => u.points != 0)
            .sort((a,b) => {return b.points - a.points})
            .slice(0, props.max || -1)
            .map((u, index) => {
                console.log(u)
                return(
                    <tr className={(u.username == props.state.username ? "highlighted-user" : "")} key={u.username}>
                    <td className="rank" width = "10%">{index + 1}</td>
                    <td width = "70%">{u.username}</td>
                    <td width = "20%">{u.points}</td>
                    </tr>

                )
            })
        }
        </tbody>
        </table>
    )
}



export default Leaderboard;
