import { useLocation } from 'react-router-dom';
import {useState} from 'react'
import ManagePlayerControls from './admin-components/ManagePlayerControls.js'
import GameweekControls from './admin-components/GameweekControls.js'

import './Admin.css'


function Admin(){ 
    const location = useLocation();
    const state = location.state;
    const [playerControlsVisible, setPlayerControlsVisible] = useState(false)
    const [gameweekControlsVisible, setGameweekControlsVisible] = useState(false)
    console.log("STATE", state)

    const updateLeaderboard = async () => {
        const scoreUpdateRequest = await fetch(state.API_BASE + `/accounts/updatescores`, {
            method: "PATCH",
            headers: {
                "authorization" : `Bearer ${state.token}`,
            }}).catch(e => console.log(e))
    }

    const updateWeek = async (week) => {
        console.log("SETUP to be updated", state.setup)
        const newSetup = state.setup
        newSetup.latestWeek = week;
        const currentSetup = await fetch(state.API_BASE + '/gameSetup', {
            method: "PATCH",
            headers: {
                "authorization" : `Bearer ${state.token}`,
                "Content-Type" : "application/json"
            },
            body: JSON.stringify(newSetup)
        })
    }

    return(
        <div className="admin-panel-container">
            <a href="/">Back to Home</a>
            <h1>Admin Panel</h1>
            <button className = "default-admin-button" onClick={() => setPlayerControlsVisible(true)}>Manage Players</button>
            <ManagePlayerControls state={state} visible = {playerControlsVisible}/>

            <button className = "default-admin-button" onClick={() => setGameweekControlsVisible(true)}>Modify Gameweeks</button>
            <GameweekControls state={state} visible = {gameweekControlsVisible}/>


            <button className='leaderboard-update-button default-admin-button' onClick={() => updateLeaderboard()}>Update Leaderboard Scores</button>

            <div className="current-gameweek-settings">
                <label htmlFor="latest-week-input">Set current Gameweek</label>
                <input id="latest-week-input" type="number" placeholder={state.setup.latestWeek}/>
                <button onClick={() => updateWeek("" + document.getElementById("latest-week-input").value)}>Update Current Gameweek</button>
            </div>

        </div>
    )
}

export default Admin;