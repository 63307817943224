

function PlayerPerformanceViewer(props){

    const performance = props.performance
    const present = performance.present
    return(
        <div className="player-performance-viewer">
                    <p className="player-performance-name">{props.name}</p>
                    <p className={present ? "" : "hidden"}>Appeared: {props.setup.presentPoints * (performance.present ? 1 : 0)}pts</p>
                    <p className={performance.goalsScored > 0 && present ? "" : "hidden"}>Goals: {performance.goalsScored} * {props.setup.goalPoints}</p>
                    <p className={performance.assists > 0 && present ? "" : "hidden"}>Assists: {performance.assists} * {props.setup.assistPoints}</p>

                    <p className={props.cleanSheetPoints > 0 && present ? "" : "hidden"}>Only conceded {performance.goalsConceded}: {props.cleanSheetPoints}pts</p>


                    <p className={performance.bonusPoints != 0 && present ? "" : "hidden"}>Bonus: {performance.bonusPoints}pts</p>
                    <div className="bonusMessage">
                        <p className={performance.bonusMessage && performance.bonusMessage.length > 0 && present ? "" : "hidden"}> {performance.bonusMessage}</p>
                    </div>

                    <p className={props.isCaptain ? "" : "hidden"}>Captain: 2x points</p>
                    <hr></hr>
                    <p>total: {props.points} points</p>
        </div>

    )



}


export default PlayerPerformanceViewer;