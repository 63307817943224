import {useEffect, useState} from 'react';
// import './PlayerCard.css'
import './StarPlayerCard.css'
import captainIcon from './captain-band.png'
import removeIcon from './cancel.png'
import PlayerPerformanceViewer from './PlayerPerformanceViewer';

function StarPlayerCard(props){  
    
    const setup = props.setup
    const player = props.player
    const performance = props.performance
    const [breakdownVisible, setBreakdownVisible] = useState(false)

    // console.log("player card props:", player.position);
    let position = player.position
 
    let pointRules = {
        position: "unknown",
        assistPoints: 4,
        cleanSheetPoints: 2,
        goalPoints: 5,
        ownGoalPoints: -2,
        presentPoints: 2
    }

    if(setup.gkPositions.includes(position.toUpperCase())) pointRules = setup.gkPoints
    if(setup.defPositions.includes(position.toUpperCase())) pointRules = setup.defPoints
    if(setup.midPositions.includes(position.toUpperCase())) pointRules = setup.midPoints
    if(setup.fwdPositions.includes(position.toUpperCase())) pointRules = setup.fwdPoints


    // console.log(`${props.player.name} is a ${pointRules.position} also ${JSON.stringify(pointRules)}`)
    const calculatePoints = () => {
        let points = 0;
        if(!performance.present) return 0;
        points += pointRules.goalPoints * performance.goalsScored;
        points += pointRules.assistPoints * performance.assists;
        points += (performance.present ? pointRules.presentPoints : 0);
        points += performance.bonusPoints;
        points += calcCleanSheetPoints();

        // points += setup.assistPoints * performance.assists;
        return points * (props.isCaptain ? 2 : 1);
    }
    
    const calcCleanSheetPoints = () => {
        // console.log("calculating clean sheet points:", performance)
        // console.log(pointRules.cleanSheetPoints)
        let points = pointRules.cleanSheetPoints
        points -= (2 * performance.goalsConceded)
        if (points < 0) return 0;
        else return points;
    }

    return(
        <div onClick = {() =>  setBreakdownVisible(!breakdownVisible)} className={`star-player-card ${props.extraClasses}`}>
            <div className="player-info">
                <p className="player-name">{player.name}</p>
                <div className="vertical-divider"></div>
                <p className="player-position">{player.position}</p>
            </div>
            <div className="star-content">
                <div className='star-points'><span>{calculatePoints()}</span><p>PTS</p></div>

                <div className="star-stats">
                <p className={performance.goalsScored < 1 ? "hidden" : ""}><span>{performance.goalsScored}</span> goal{performance.goalsScored > 1 ? "s" : ""}</p>

                <p className={performance.assists < 1 ? "hidden" : ""}><span>{performance.assists}</span> assist{performance.assists > 1 ? "s" : ""}</p>

                <p className={(!performance.present || calcCleanSheetPoints(performance) < 1 ? "hidden" : "")}><span>{calcCleanSheetPoints(performance)}</span> defense pt{calcCleanSheetPoints(performance) > 1 ? "s" : ""}</p>

                <p className={(performance.bonusPoints) == 0 ? "hidden" : ""}><span>{performance.bonusPoints}</span> bonus pt{performance.bonusPoints != 1 ? "s" : ""}</p>

                </div>

                <div className="star-message"><p>{performance.bonusMessage}</p></div>

            </div>


            <PlayerPerformanceViewer performance = {performance} setup = {pointRules} 
                                     name = {player.name} points = {calculatePoints()}
                                     isCaptain = {props.isCaptain} cleanSheetPoints = {calcCleanSheetPoints()}
            />

        </div>
) 

}

export default StarPlayerCard