
import './Header.css'

function Header(){
    return(

            <header>
                <ul className='header-menu'>
                    {/* <li><a href="/">Home</a></li>
                    <li><a href="/team">Team</a></li> */}
                    <p className='navbar-title'>CSS Fantasy <span className='beta'>(BETA)</span></p>
                    {/* <li><a href="/">Login</a></li>
                    <li><a href="/admin">Admin</a></li> */}
                </ul>
            </header>


    )



}



export default Header;