import {useEffect, useState} from 'react';
import './ManagePlayerContainer.css'

function ManagePlayerControls(props){
    const state = props.state
    const[playerName, setPlayerName] = useState("")
    const[playerPosition, setPlayerPosition] = useState("")
    const[players, setPlayers] = useState([])



    useEffect(() => {
        getPlayers()
    }, [])
    


    
    //fetch and store all players in the club from the database
    const getPlayers = async () => {
        // console.log("attempting to get all club players:", state.token)
        const tokenString = `Bearer ${state.token}`
        const players  = await fetch(state.API_BASE + '/players', {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json()).catch(e => console.log(e));
        console.log("players fetched:", players)
        setPlayers(players.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    }


    const saveAllPlayers = async () => {
        if (players.length === 0) window.alert("Can't save 0 players")

        else{
            console.log("attempting to update all players")
            navigator.clipboard.writeText(JSON.stringify(players));
            const tokenString = `Bearer ${state.token}`
            const updatedPlayers  = await fetch(state.API_BASE + '/players/update', {
                method: "PATCH",
                headers: {
                    "authorization" : tokenString,
                    "Content-type": "application/json"
                },
                body: JSON.stringify({updatedPlayers: players})
            }).then(res => res.json()).catch(e => console.log(e));
            window.alert(updatedPlayers.message)
            await getPlayers()
            console.log(updatedPlayers)
        }


    }
    
    const handlePlayerSave = async () => {
        if (playerName.length < 1 || playerPosition.length < 1){
            console.log("ensure player and position have been filled in")
            return
        }
        console.log("hi")
        const newPlayer = {
            name: playerName,
            position: playerPosition
        }
        const players  = await fetch(props.state.API_BASE + '/players/new', {
            method: "POST",
            headers: {
                "authorization" : `Bearer ${props.state.token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                newPlayer
            })
        }).then(res => res.json()).catch(e => console.log(e));
        if(parseInt(players.code) >= 400){
            window.alert(players.message)
        }
        await getPlayers();
    }


    const handleTextBox = (player, field) => {
        const playersCopy = [...players]
        playersCopy.map(p => {
            if (p._id === player.id) p[field]= player[field]
            }
        )
        console.log(playersCopy)
        setPlayers(playersCopy)
    }

    console.log(props.visible)
    return(

        <div className={"manage-players-container " + (props.visible? "" : " hidden")}>
            
            
            <button className='default-admin-button' onClick={e => saveAllPlayers()}>Save Changes</button>
            <table id="admin-player-table">
                <tr>
                        <th width="50%">Player</th>
                        <th width="50%">Position</th>

                </tr>
            {players.map(p => { return (

                    <tr>
                        <td className='player-table-cell'><input className='player-input' type="text" value = {p.name} onChange={e => {console.log(e.target.value); p.name = e.target.value; handleTextBox(p, "name")}} /></td>
                        <td className='position-table-cell'><input className='position-input' type="text"  value = {p.position} onChange={e => {console.log(e.target.value); p.position = e.target.value; handleTextBox(p, "position")}} /></td>
                    </tr>

            )})
            }
            </table>





            <div className="add-player-container">
                <h2>New player</h2>
                <label htmlFor="">Name:</label>
                <input type="text" name="" id="" onChange={e => setPlayerName(e.target.value)} />
                <label htmlFor="">Position:</label>
                <input type="text" name="" id="" onChange={e=> setPlayerPosition(e.target.value)}/>
                <button className="default-admin-button" id="add-player-button" onClick = {handlePlayerSave}>Add Player</button>
            </div>
        </div>


    )
}

export default ManagePlayerControls;