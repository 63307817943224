import NumberPicker from "./NumberPicker"
import { useState } from "react"
import Checkbox from "./Checkbox"

function PerformanceRow(props){
    const [performance, setPerformance] = useState(props.performance)
    const [val, setVal] = useState(true)

    //increment a useState object's given field
    const incrementValue = (object, objectSetter, fieldString, increment, parentUpdater) => {
        const newObject = JSON.parse(JSON.stringify(object));//copy object
        newObject[fieldString] += increment//increment given field
        objectSetter(newObject)//replace original object
        parentUpdater(newObject, [fieldString])
    }
    
    const handleCheckBox = (object, objectSetter, fieldString, checked, parentUpdater) => {
        const newObject = JSON.parse(JSON.stringify(object));
        newObject[fieldString] = checked;
        objectSetter(newObject)
        parentUpdater(newObject, [fieldString])
    }

    const handleMessageBox = (object, objectSetter, fieldString, checked, parentUpdater) => {
        const newObject = JSON.parse(JSON.stringify(object));
        newObject[fieldString] = checked;
        objectSetter(newObject)
        parentUpdater(newObject, [fieldString])
    }

    // console.log("performance row, performance received:", props.performance)

    return(
                <tr>
                    <td>
                        <p className="table-player-name">{props.performance.playerName}</p>
                    </td>
                    <td>
                    <Checkbox
                        val = {props.performance.present}
                        labelText = {props.performance.present}
                        updateVal = {() => handleCheckBox(performance, setPerformance, 'present', !props.performance.present, props.handlePerformanceUpdate)}
                    />
                    </td>
                    <td>
                    <NumberPicker labelText="Goals" 
                        value={props.performance.goalsScored} 
                        increase={()=>incrementValue(props.performance, setPerformance, 'goalsScored', 1, props.handlePerformanceUpdate)}
                        decrease={()=>incrementValue(props.performance, setPerformance, 'goalsScored', -1, props.handlePerformanceUpdate)}
                    />   
                    </td>
                    <td>
                    <NumberPicker labelText="Assists" 
                        value={props.performance.assists} 
                        increase={()=>incrementValue(props.performance, setPerformance, 'assists', 1, props.handlePerformanceUpdate)}
                        decrease={()=>incrementValue(props.performance, setPerformance, 'assists', -1, props.handlePerformanceUpdate)}
                    />
                    </td>
                    <td>
                        <NumberPicker labelText="Conceded" 
                            value={props.performance.goalsConceded} 
                            increase={()=>incrementValue(props.performance, setPerformance, 'goalsConceded', 1, props.handlePerformanceUpdate)}
                            decrease={()=>incrementValue(props.performance, setPerformance, 'goalsConceded', -1, props.handlePerformanceUpdate)}
                        />
                    </td>
                    <td>
                    <NumberPicker labelText="Bonus" 
                        value={props.performance.bonusPoints} 
                        increase={()=>incrementValue(props.performance, setPerformance, 'bonusPoints', 1, props.handlePerformanceUpdate)}
                        decrease={()=>incrementValue(props.performance, setPerformance, 'bonusPoints', -1, props.handlePerformanceUpdate)}
                    />
                    </td>
                    <td>
                        <label htmlFor="">Message  </label>
                        <input type="text" value={props.performance.bonusMessage}onChange={e => handleCheckBox(performance, setPerformance, 'bonusMessage', e.target.value, props.handlePerformanceUpdate)}/>
                    </td>
                </tr>

    )
}

export default PerformanceRow