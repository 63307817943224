function NumberPicker(props){
    return(
        <div className={"number-picker-container " + props.className}>
            <label htmlFor="">{props.labelText}</label>
            <div className="increment-buttons-container">
                <button
                    onClick={props.decrease }
                >-</button>

                <p>{props.value}</p>
            
                <button
                    onClick={props.increase }
                >+</button>
            </div>


        </div>
    )
}

export default NumberPicker