import Leaderboard from "./Leaderboard";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import API_BASE from './api';



function LeaderboardPage(){
    const location = useLocation();
    const state = location.state || {username:""};
    const navigate = useNavigate();

    const [userPoints, setUserPoints] = useState([])


    useEffect( () => {
        getSeasonLeaderboard(setUserPoints)
    }, [])

    const getSeasonLeaderboard = async (setter) => {
        const userPoints  = await fetch(API_BASE + `/accounts/leaderboard`, {
            method: "GET",
        }).then(res => res.json())
          .then(res=> setter(res))
          .then(res => console.log(res))
    }

    return(
        <div className="leaderboard-page">
            <h1>Season Leaderboard</h1>
            <Leaderboard userPoints={userPoints} pointsLabel = "Points" state={state}></Leaderboard>
        </div>
    )
}




export default LeaderboardPage