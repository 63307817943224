import PlayerCard from "./PlayerCard";

function ActiveTeam(props){
    let squad = props.squad;
    
    const sortedSquad = squad.sort((a,b) => {if (props.getCurrentSelection().captain == a._id) return -1;
                                 else return 1
                                }
    )


    // console.log(sortedSquad)
    return(
        <div className="active-team">
        {
        sortedSquad.map((p, i) => {return(
            <PlayerCard 
            setup = {props.setup}
            performance = {props.getMatchingPerformance(p._id, props.selectedWeek)}
            updateSelection = {props.updateSelection}
            player = {p} key={p._id}
            isCaptain = {props.getCurrentSelection().captain == p._id}
            extraClasses = {` remove position${i.toString()} `  
                             + (props.getCurrentSelection().captain == p._id ? " captain " : "")
                             + (parseInt(props.selectedWeek) >= parseInt(props.setup.latestWeek) ? " modifiable " : " unmodifiable ")
            }

            removable = {true}
        />
        )})
        }
        </div>

    )
}

export default ActiveTeam;