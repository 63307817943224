import {useEffect, useState} from 'react';
import './PlayerCard.css'
import captainIcon from './captain-band.png'
import removeIcon from './cancel.png'
import PlayerPerformanceViewer from './PlayerPerformanceViewer';

function PlayerCard(props){  
    
    const setup = props.setup
    const player = props.player
    const performance = props.performance
    const [breakdownVisible, setBreakdownVisible] = useState(false)

    // console.log("player card props:", player.position);
    let position = player.position
 
    let pointRules = {
        position: "unknown",
        assistPoints: 4,
        cleanSheetPoints: 2,
        goalPoints: 5,
        ownGoalPoints: -2,
        presentPoints: 2
    }

    if(setup.gkPositions.includes(position.toUpperCase())) pointRules = setup.gkPoints
    if(setup.defPositions.includes(position.toUpperCase())) pointRules = setup.defPoints
    if(setup.midPositions.includes(position.toUpperCase())) pointRules = setup.midPoints
    if(setup.fwdPositions.includes(position.toUpperCase())) pointRules = setup.fwdPoints


    // console.log(`${props.player.name} is a ${pointRules.position} also ${JSON.stringify(pointRules)}`)
    const calculatePoints = () => {
        let points = 0;
        if(!performance.present) return 0;
        points += pointRules.goalPoints * performance.goalsScored;
        points += pointRules.assistPoints * performance.assists;
        points += (performance.present ? pointRules.presentPoints : 0);
        points += performance.bonusPoints;
        points += calcCleanSheetPoints();

        // points += setup.assistPoints * performance.assists;
        return points * (props.isCaptain ? 2 : 1);
    }
    
    const calcCleanSheetPoints = () => {
        // console.log("calculating clean sheet points:", performance)
        // console.log(pointRules.cleanSheetPoints)
        let points = pointRules.cleanSheetPoints
        points -= (2 * performance.goalsConceded)
        if (points < 0) return 0;
        else return points;
    }

    return(
        <div onClick = {() =>  setBreakdownVisible(!breakdownVisible)} className={`player-card ${props.extraClasses}`}>
            <div className="player-info">
                <p className="player-name">{props.player.name}</p>
                <div className="vertical-divider"></div>
                <p className="player-position">{props.player.position}</p>
            </div>
            {/* <div className = "captain-band-container icon-button"
                 onClick={() => {
                    const p = {
                        _id: props.player._id,
                        name: props.player.name,
                        position: props.player.position}; 
                    props.updateSelection(p, "captain")
                    console.log("player card captain click:", p)}}
            >
                <img src={captainIcon} alt="" />
            </div> */}



            <div className="player-card-options">
            <button    
                className='add-player-button gameweek-modifier'     
                onClick = {() => {
                    const p = {
                        _id: props.player._id,
                        name: props.player.name,
                        position: props.player.position}; 
                    props.updateSelection(p, "add")
                    console.log("player card add click:", p)}}> Select
            </button>        
            <div 
                className='captain-player-button gameweek-modifier icon-button'     
                onClick = {() => {
                    const p = {
                        _id: props.player._id,
                        name: props.player.name,
                        position: props.player.position};
                    props.updateSelection(p, "captain")
                    // console.log("player card captain click:", p)
                    }}>
                    <img src={captainIcon} alt="" />
                    <p className="button-label">Captain</p>
            </div>
            <div className={"breakdown-button icon-button"}>
                <p className="player-points">{(calculatePoints())} pts</p>
                <p>view stats</p>
            </div>
            <div    
                className='remove-player-button gameweek-modifier icon-button'     
                onClick = {() => {
                    const p = {
                        _id: props.player._id,
                        name: props.player.name,
                        position: props.player.position}; 
                    props.updateSelection(p, "remove")
                    // console.log("player card remove click:", p)
                }}> 
                    <img src={removeIcon} alt="" />
                    <p className="button-label">Remove</p>
            </div>
            </div>

            <PlayerPerformanceViewer performance = {performance} setup = {pointRules} 
                                     name = {props.player.name} points = {calculatePoints()}
                                     isCaptain = {props.isCaptain} cleanSheetPoints = {calcCleanSheetPoints()}
            />

        </div>
) 

}

export default PlayerCard