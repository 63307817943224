import './App.css';
import { useState, useEffect } from 'react';
import CreateAccountForm from './CreateAccountForm'
import Admin from './Admin'
import Team from './Team'
import Header from './Header'
import LeaderboardPage from './LeaderboardPage';

import {
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

function App() {
  return (
    <>
      <Header/>

      <HashRouter>
        <Routes>
          <Route path = "/" element = {<CreateAccountForm/>}/>
          <Route path = "/admin" element = {<Admin/>}/>
          <Route path = "/team" element = {<Team/>}/>
          <Route path = "/leaderboard" element = {<LeaderboardPage/>}/>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
