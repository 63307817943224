import {useEffect, useState} from 'react';
import './CreateAccountForm.css'
import TeamViewer from './TeamViewer'
import Header from './Header'
import Leaderboard from './Leaderboard';
import cssHero from './cssHero compressed.jpg'
import cssHeroMobile from './cssHeroCompressedMobile.jpg'
import cssHeroMobileVariable from './cssHero compressed variable.jpg'
import API_BASE from './api';

import { useNavigate } from 'react-router-dom';
// require('dotenv').config();


function CreateAccountForm(){
	const [newPassword, setPassword] = useState("");
	const [newUsername, setUsername] = useState("");
    const [token, updateToken] = useState("");
    const [teamContent, updateTeamContent] = useState({username: ""})
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [isTeamLoaded, setTeamLoaded] = useState(false)
    const [verifiedUsername, setVerifiedUsername] = useState("")
    const [role, setRole] = useState("user")
    const [gameSetup, setGameSetup] = useState({latestWeek: "20", maxPlayers: 5, setupLoaded: false})
    const [awaitingApi, setAwaitingApi] = useState(false)
    const [instructionsVisible, setInstructionsVisible]  = useState(false)
    const [userPoints, setUserPoints] = useState([])

	useEffect( () => {
		// console.log("hi");
        getSeasonLeaderboard(setUserPoints)
	}, [])

    const navigate = useNavigate();


    const getTeam = async () =>{
        // console.log("attempting to get team with token:", token)
        const tokenString = `Bearer ${token}`
        const team  = await fetch(API_BASE + '/teams', {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json()).then(res => {updateTeamContent(res);setTeamLoaded(true);console.log(res)}).catch(e => console.log(e));
    }

    const getSetup = async() => {
        const setup  = await fetch(API_BASE + '/gameSetup', {
            method: "GET",
        }).then(res => res.json()).then(res => {console.log("setup acquired:", res); setGameSetup(res); return res}).catch(e => console.log(e));
        return setup;
    }

    const viewFullLeaderboard = () => {
        const state  = {
            username: "",
            API_BASE: API_BASE
        }

        navigate('/leaderboard', {state: state})
    }

    const getSeasonLeaderboard = async (setter) => {
        const userPoints  = await fetch(API_BASE + `/accounts/leaderboard`, {
            method: "GET",
        }).then(res => res.json())
          .then(res=> setter(res))
          .then(res => console.log(res))
    }

//attempts to retrieve a valid jwt from server given username and password (login)
    const login = async () =>{
        const setup = await getSetup();
        setAwaitingApi(true)
        const myUsername = newUsername;
        const myPassword = newPassword;
        try{
            const data = await fetch(API_BASE + '/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: myUsername,
                    password: myPassword
                })
            }).then(res => res.json())
            if(data.accessToken == null){
                window.alert("invalid credentials, try again...")
            }
            else{
                updateToken(data.accessToken)
                setLoggedIn(true)
                setVerifiedUsername(data.username);
                setRole(data.role)
                const state  = {token: data.accessToken,
                                username: data.username,
                                role: data.role,
                                API_BASE: API_BASE,
                                setup: setup
                }
                navigate('/team', {state: state})
                console.log("login successful")
            }
            
        }catch(err){
            console.log(`ERROR ${err}`)
        }

        // console.log("login response", data);
    }

    const handleSelectionsUpdate = function(selections){
        updateTeamContent(selections)
        // console.log("Create Account team selections: ", selections, teamContent)
    }

    const logout = () => {
        updateTeamContent({username: ""})
        updateToken(""); 
        setLoggedIn(false)
    }

//attempt to create a brand new account given a username and password
	const createAccount = async () => {
        setAwaitingApi(true)
        const username = newUsername;
        const password = newPassword;

        if (username.includes(" ")){
            window.alert("warning: chosen username contains a space")
            return
        }
        // console.log("attempting account creation...")
        try{
            const data = await fetch(API_BASE + "/accounts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: username,
                    password : password
                })
            })
            console.log("status", data.status, (data.status / 100).toFixed(0))
            if (data.status == 400) window.alert("Username token or password incorrectly formatted")
            else if (true && (data.status / 100).toFixed(0) == 2) window.alert("Account successfully created!")
            setAwaitingApi(false)

        }catch(err){
            console.log(err);
        }
	}

    return (
        <div className="account-controls">
            <div className="background">
                <img className = {"desktop-only"} src={cssHero} alt="Css players having fun in a park" />
                <img className = {"mobile-only"} src={cssHeroMobileVariable} alt="Css players having fun in a park" />
            </div>
            {isLoggedIn ? (
                    <div className="logged-in-content">
                        <button className="logout-button" onClick={() => logout()}>Logout</button>
                        <button onClick={() => {getTeam()}}>Select / View your team</button>
                        {/* <button onClick={() => {getSetup();getTeam()}}>Get Team</button> */}
                        <TeamViewer API_BASE={API_BASE} token={token} username={verifiedUsername} 
                                    updateTeamContent = {handleSelectionsUpdate} role={role}
                                    selections = {isTeamLoaded ? teamContent : []}></TeamViewer>
                    </div>

                ):  (
                
                <div className="homepage-content">



                    <div className="homepage-info">
                        <div className="instructions-toggle"
                             onClick={() => {console.log("instructions toggled"); document.getElementById("instructions-container").classList.toggle("hidden-instructions"); document.getElementById("instructions-container").classList.toggle("visible-instructions")}}>
                            <h2 className='homepage-more-info'>How to play?</h2>
                        </div>
                        <div id="instructions-container" className={"instructions-container " + (instructionsVisible ? "visible-instructions" : "hidden-instructions")}>
                            <ul id="instructions" className={"instructions"}>
                                <li><span className='instructions-span'>Build your dream team</span> of 5 Bristol Computer Science football players</li>
                                <li><span className='instructions-span'>Earn points</span> based on their real-life performances</li>
                                <li><span className='instructions-span'>Check-in every week</span> to view the leaderboard and refine your squad</li>
                            </ul>
                        </div>


                    </div>

                    <div className="create-account-form">    

                        <div className="field">
                            <label htmlFor="">Username</label>
                            <input name="username" type="text" onChange={e => setUsername(e.target.value)} />
                        </div>

                        <div className="field">
                            <label htmlFor="">Password <span><em></em></span></label>
                            <input name="password" type="password" onChange={e => setPassword(e.target.value)} />
                        </div>

                        <div className="account-buttons-container">
                            <button className="sign-up-button" onClick= {createAccount}>Sign up</button>
                            <button className="login-button" onClick= {() => login()}>Login</button>
                        </div>
                        <p className={"login-info " + (awaitingApi ? "" : " hidden ")}>Your initial request may take a moment... (i'm using a cheap host server that needs waking up)</p>

                        </div>

                </div>

                )
            }
            <h1 className="hero-header">Intramural Fantasy Football</h1>
        
        <div className="leaderboard-preview-container">
            <h2>Season Leaderboard</h2>
            <Leaderboard userPoints = {userPoints} max = {5} state = {{username: ""}}></Leaderboard>
            <button className="view-full-leaderboard-button" onClick={() => viewFullLeaderboard()}><p>See Full Leaderboard</p></button> 
        </div>

        <div className="patch-notes" id="patch-notes">
        <p><strong>patch notes. 21 Dec 2023.</strong></p>
            <ul>
                <li>New feature: click users on weekly leaderboard to view their team</li>
                <li>New feature: view top 6 performances for each week</li>
                <li>Fixed glitch to do with gameweek 10+ making all weeks (appear) modifiable</li>
            </ul>


        <p><strong>patch notes. 12 Nov 2023.</strong></p>
            <ul>
                <li>New layout for squad selections</li>
                <li>Added filters for viewing all squad players</li>
            </ul>

            <p><strong>patch notes. 23 oct 2023.</strong></p>
            <ul>
                <li>season leaderboard page added</li>
                <li>season leaderboard preview added to homepage</li>
                <li>new team selections automatically apply to future weeks</li>
                <li>{"team page now defaults to current week (slightly buggy)"}</li>
                <li>reworked login page layout</li>
                <li>added patch notes section</li>
                <br></br>
                <li>improved admin panel layout</li>
                <li>player 'goals conceded' total now defaults to opponent's score</li>
                <li>other admin things I've forgotten</li>

            </ul>
        </div>

        </div>
    )
        }
export default CreateAccountForm;