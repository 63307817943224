import PlayerCard from './PlayerCard'
import {useEffect, useState} from 'react';


function MiniTeamViewer(props){
    const selection = props.selection

    if(selection.players == null){
        return <div className="mini-team-viewer hidden">
        <p>Mini Team Viewer</p>
    </div>
    }
    else{

    const setup = props.setup
    const players = props.players
    const teamPlayers = players.filter(p => {
        return selection.players.some(sp => p._id === sp)
    })
    console.log("selection", selection.players);
    console.log("team players", teamPlayers);
    return(
        <div className={"mini-team-viewer " + (props.hidden ? "hidden" : "")}>
            <h3>{selection.username}'s Team (GW {selection.week})<br></br> {selection.points} points</h3>
            <div className="mini-team-players-container">
                {
                    teamPlayers.map(p => {
                    console.log(p);
                    return(<p>{p.name} {selection.captain === p._id ? "[C]" : ""}</p>)

                    })
                }
            </div>

            <button onClick={() => props.setHidden(true)}>Close</button>
        </div>
        )
    }
}

export default MiniTeamViewer;