import {useEffect, useState} from 'react';
import './TeamViewer.css'
import PlayerCard from './PlayerCard'
import NumberPicker from './admin-components/NumberPicker'
import SquadView from './SquadView'

const LATEST_WEEK = "3"
const MAX_TEAM_SIZE = 5;



function TeamViewer(props){
    const [allPlayers, setPlayers] = useState([])
    const [selectedWeek, setWeek] = useState(1)

    const [performances, updatePerformances] = useState([])
    const [teamContent, updateTeamContent] = useState({username: ""})
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [isTeamLoaded, setTeamLoaded] = useState(false)
    const [verifiedUsername, setVerifiedUsername] = useState("")
    const [role, setRole] = useState("user")
    const [setup, setSetup] = useState({latestWeek: "12", maxPlayers: 5, setupLoaded: false})

    useEffect( () => {
        console.log("render")
        console.log("setup: ", setup)
        const f = async () => {
            getSetup()
        }
        f()
    }, [])
    // const selections = props.selections

    if (props.selections.length > 0) console.log("team viewer props recieved: ", props.selections)

    function getCurrentSelection(){
        const matchingSelections = props.selections.filter(s => parseInt(s.week) == selectedWeek)
        if(matchingSelections.length > 0){
            return selectedTeam = matchingSelections[0]
        }
        else {
            console.log("no matching selection")
            return [];
        }
    }
    
    // const getTeam = async () =>{
    //     console.log("attempting to get team with token:", props.token)
    //     const tokenString = `Bearer ${props.token}`
    //     const team  = await fetch(props.API_BASE + '/teams', {
    //         method: "GET",
    //         headers: {
    //             "authorization" : tokenString
    //         }
    //     }).then(res => res.json()).then(res => {updateTeamContent(res);setTeamLoaded(true);console.log(res)}).catch(e => console.log(e));
    // }

    const getSetup = async() => {
        console.log("attempting to get game setup with token:", props.token)
        const tokenString = `Bearer ${props.token}`
        const setup  = await fetch(props.API_BASE + '/gameSetup', {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json()).then(res => {console.log(res);setSetup({...res, setupLoaded: true})}).catch(e => console.log(e));
        return setup;
    }

    const incrementValue = (getter, setter, inc, min, max) => {
        let val = getter
        if(val + inc > max) val = max
        else if(val + inc < min) val = min
        else val = val + inc
        console.log(val)
        setter(val)
    }

    //add a player to a week's selection on the front-end
    const handleAddPlayer = (player) => {
        if(props.selections.length == 0){
            console.log("NO SELECTIONS YET")
            const newSelection = {
                week: setup.latestWeek,
                players: [player._id],
                captain: player._id,
                username: props.username
            }
            setWeek(setup.latestWeek)
            props.selections.push(newSelection)
            // WE WANT TO HANDLE WHEN THEY HAVE NO SELECTIONS,
            // POTENTIALLY SAVING AN EMPTY SELECTION TO MONGODB AND RECCALLING GET TEAMS, 
            // OR GENERATING A SELECTION object THAT IS LATER RESTRUCTURED SERVER-SIDE WHEN THEY SAVE TEAM
        }
        else{
            const currentSelection = getCurrentSelection()
            let message = "successfully added player"
            const currentTeam = getCurrentSelection().players || []
            if(currentTeam.length >= setup.maxPlayers){
                console.log(`no more than ${setup.maxPlayers} players allowed in one squad`);
                return
            }
            let filteredPlayers = currentSelection.players.filter(p => p._id === player._id);
            if(filteredPlayers.length > 0){
                console.log("player already in team:", player);
                return;
            }
            const selectionsCopy = [...props.selections]
            currentSelection.players.push(player)
            props.updateTeamContent(selectionsCopy)
            console.log("updated provisional selection:", selectionsCopy)

        }
    }



    //remove a player from a week's selection on the front-end
    const handleRemovePlayer = (player) => {
        const currentSelection = getCurrentSelection();
        //check if team is empty
        console.log("original player list:", currentSelection.players)
        const originalLength = currentSelection.players.length
        if (originalLength == 0){
            console.log("can't remove player from empty team")
            return
        }

        //filter out our player
        const filteredTeam = currentSelection.players.filter(p => {
            console.log("comparison", p._id != player._id)
            ; return p._id != player._id})
        console.log("filtered team for removal: ", filteredTeam)
        //no match
        if(filteredTeam.length == originalLength){
            console.log("player isn't in team")
            return
        }

        //update model to remove the player 
        console.log("player removed")
        let selectionsCopy = [...props.selections];
        selectionsCopy = selectionsCopy.map(s => {
            if (parseInt(s.week) == selectedWeek){
                s.players = filteredTeam
            }
            return s
        })
        props.updateTeamContent(selectionsCopy)

    }

    //change current week's selected captain
    const handleCaptainPlayer = (player) => {
        const currentSelection = getCurrentSelection()
        //check player is in team
        if (currentSelection.players.map(p => p._id).includes(player._id)){

            let selectionsCopy = [...props.selections]
            selectionsCopy = selectionsCopy.map(s => {
                if(s.week == selectedWeek){
                    s.captain = player._id
                }
                return s;
            })
            props.updateTeamContent(selectionsCopy)
        }
        else{
            console.log("attempted captain isn't in team")
        }
    }

    const handlePlayerSelected = (player, action) => {
        if(parseInt(selectedWeek) < parseInt(setup.latestWeek)){
            window.alert("can't modify a previous week");
            return
        }
        if(action == "add"){
            handleAddPlayer(player)
        }
        else if (action == "remove"){
            handleRemovePlayer(player)
        }
        else if (action == "captain"){
            handleCaptainPlayer(player)
        }
    }

    //fetches performances from database for a given week
    const getWeekPerformances = async (week) => {
        console.log("attempting to get performances selection for week", week)
        const tokenString = `Bearer ${props.token}`

        const result  = await fetch(props.API_BASE + `/performances/week/${week}`, {
            method: "GET",
            headers: {
                "authorization" : tokenString,
            }
        }).then(res => res.json())
          .then(res => {console.log("fetched performances:", res); return res})
          .then(res => {const newPerformances = performances.concat(res);
                              console.log("new Performances:", newPerformances)
                              updatePerformances(newPerformances)})
          .catch(e => console.log(e));
    }


    //get the performance from state corresponding to given player and week
    const getMatchingPerformance = (playerId, week) => {
        console.log("Performances:", performances)
        const matchingPerformances = performances.filter(p => {return (p.player == playerId && p.week == week)})
        console.log(`matching performances for ${playerId} on week ${week}:`, matchingPerformances)
        if(matchingPerformances.length < 1){
            return {
                _id: "64f59a850e78b30100e49f9e", player: "64f592d8da741df1fbbc9035",
                week:week, present:false,
                goalsScored:0, assists:0,
                goalsConceded:0, ownGoals:0,
                bonusPoints:0, __v:0
            }
        }
        else return matchingPerformances[0];
  
    }

    //save an updated (or new) selection to the server
    const saveSelection = async (props) => {
        console.log("attempting to save selection for week", selectedWeek)
        const uploadSelection = JSON.parse(JSON.stringify(getCurrentSelection()))
        uploadSelection.players = uploadSelection.players.map(p => p._id)
        console.log("Upload SELECTION", uploadSelection);
        const tokenString = `Bearer ${props.token}`

        const players  = await fetch(props.API_BASE + '/teams/new', {
            method: "PUT",
            headers: {
                "authorization" : tokenString,
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                uploadSelection
            })
        }).then(res => res.json()).catch(e => console.log(e));
    }

    const  getPlayers = async (props) => {
        console.log("attempting to get all club players:", props.token)
        const tokenString = `Bearer ${props.token}`
        const players  = await fetch(props.API_BASE + '/players', {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json()).catch(e => console.log(e));
        setPlayers(players);
    }
    

    if (props.selections.length > 0){
        var selectedTeam;
        //find corresponding week selection
        const matchingSelections = props.selections.filter(s => {console.log(s.week, selectedWeek); return parseInt(s.week) == selectedWeek} )
        if(matchingSelections.length > 0){
            console.log(matchingSelections.length," teams found")
            selectedTeam = matchingSelections[0].players
        }
        //if it doesn't exist just return a valid team and update the slider accordingly
        else {
            console.log("NEW TEAM INITIATED")
            selectedTeam = []
            props.selections.push({
                week: selectedWeek,
                players: [],
                username: props.username
            })
        }
    return(
        <div className="TeamViewer">
            {/* <label>{`week: ${selectedWeek} / ${setup.latestWeek}`}</label> */}
            {/* <NumberPicker 
                value={"Week " + selectedWeek.toString()  + "/" + setup.latestWeek.toString()} 
                increase={() => incrementValue(selectedWeek, setWeek,  1, 1, parseInt(setup.latestWeek))}
                decrease={() => incrementValue(selectedWeek, setWeek, -1, 1, parseInt(setup.latestWeek))}>
            </NumberPicker> */}
            {/* <input type="number" value = {selectedWeek} min={1} max={parseInt(setup.latestWeek)} onChange={e => setWeek(e.target.value)}/> */}

            <div className="active-squad-container">
            <SquadView squad = {selectedTeam}></SquadView>
            {
                selectedTeam.map(p => 
                {console.log("captains:",p.name, p._id, getCurrentSelection().captain)
                return(
                    <PlayerCard 
                        setup = {setup}
                        performance = {getMatchingPerformance(p._id, selectedWeek)}
                        updateSelection = {handlePlayerSelected}
                        player = {p} key={p._id}
                        isCaptain = {getCurrentSelection().captain == p._id}
                        extraClasses = {" remove "
                                         + (getCurrentSelection().captain == p._id ? " captain " : "")
                                         + (selectedWeek >= setup.latestWeek ? "" : " unmodifiable ")
                        }
                        removable = {true}
                    />
                )
                }
            )  
            }
            </div>

            <NumberPicker 
                value={"Week " + selectedWeek.toString()  + "/" + setup.latestWeek.toString()} 
                increase={() => incrementValue(selectedWeek, setWeek,  1, 1, parseInt(setup.latestWeek))}
                decrease={() => incrementValue(selectedWeek, setWeek, -1, 1, parseInt(setup.latestWeek))}>
            </NumberPicker>


            <div className="current-team-controls">
                <button className={"api-button load-scores-button " + (selectedWeek < setup.latestWeek ? "" : "hidden")} 
                        onClick={() => getWeekPerformances(selectedWeek)}>Load Scores
                </button>
                <button className={"api-button submit-selection-button " + (selectedWeek >= setup.latestWeek ? "" : "hidden")} 
                        onClick={() => saveSelection(props)}>Submit Selection
                </button>
            </div>


            <button onClick={() => getPlayers(props)}>Get All Players</button>

            <div className="all-players-container">
                {allPlayers.map(p => 
                    (
                        <PlayerCard
                            setup = {setup}
                            performance = {getMatchingPerformance(p._id, selectedWeek)}
                            updateSelection = {handlePlayerSelected}
                            extraClasses = {" add "
                                            + (selectedWeek >= setup.latestWeek ? "" : " unmodifiable ")}
                            player = {p} key={p._id} id={p._id}
                            isCaptain = {false}
                        />
                    )
                )  
            }
            </div>
        </div>
    )
    }
    else {
        console.log(props)
        return (
            <div>
            <p>Click a player from the roster to begin building your team...</p>


            <button onClick={() => getPlayers(props)}>Get All Players</button>

            <div className="all-players-container">
                {allPlayers.map(p => 
                    (
                        <PlayerCard
                            setup = {setup}
                            performance = {getMatchingPerformance(p._id, selectedWeek)} 
                            updateSelection = {handlePlayerSelected}
                            player = {p} key={p._id} id={p._id}
                            extraClasses = {" add "
                                            + (selectedWeek >= setup.latestWeek ? "" : " unmodifiable ")}
                            buttonClasses = {" add "}
                            isCaptain = {false}

                        />
                    )
                )  
            }
            </div>
        </div>)
    }
}

export default TeamViewer