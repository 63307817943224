import NumberPicker from './NumberPicker'
import PerformanceRow from './PerformanceRow'

import {useEffect, useState} from 'react';

function GameweekControls(props){
    const[opponentName, setOpponentName] = useState("data not loaded")
    const[gameScore, setGameScore] = useState({home: 0, away: 0})
    const[awayScore, setAwayScore] = useState(0)
    const[week, setWeek] = useState(parseInt(props.state.setup.latestWeek))
    const [gameweeksLoaded, setGameweeksLoaded] = useState(false);
    const[selectedGameweek, setSelectedGameweek] = useState({opponent:"data not loaded"})
    const [selectedPerformances, setSelectedPerformances] = useState([])
    const [allGameweeks, setAllGameweeks] = useState([])
    const [allPlayers, setPlayers] = useState([])
    const setup = props.state.setup

    useEffect( () => {
        getGameWeeks()
    }, [])


    console.log("SETUP", props.state.setup)

    const handleGameweekSave = async () => {

        const gameweekSaveResponse  =  await fetch(props.state.API_BASE + '/gameweeks', {
            method: "POST",
            headers: {
                "authorization" : `Bearer ${props.state.token}`,
                "Content-type" : "application/json"
            },
            body: JSON.stringify({
                week: selectedGameweek.week,
                opponent: opponentName,
                performances: selectedPerformances,
                homeScore: selectedGameweek.homeScore,
                awayScore: selectedGameweek.awayScore
            })
        }).then(async res => {res.json()} ).catch(e => console.log(e));
        await getGameWeeks()
        console.log("ATTEMPTING SCORE UPDATE")
        const scoreUpdateRequest = await fetch(props.state.API_BASE + `/players/selection${selectedGameweek.week}`, {
            method: "PATCH",
            headers: {
                "authorization" : `Bearer ${props.state.token}`,
            }}).catch(e => console.log(e))
    }

    const getGameWeeks = async() => {
        const response  = await fetch(props.state.API_BASE + '/gameweeks', {
            method: "GET",
            headers: {
                "authorization" : `Bearer ${props.state.token}`,
            }
        }).then(res => res.json()).then(res => {
            res.forEach(gw => {gw.performances.forEach(p => {p.playerName = p.player.name; return p}); return gw});
            return res;
        }).catch(e => console.log(e));

         setAllGameweeks(response)
        console.log("all gameweeks updated:", response)
         setSelectedGameweek(getMatchingGameweek(response, week))


        console.log(selectedGameweek)

    }

    //gets the gameweek corresponding to current week, otherwise returns a valid gameweek and sets
    //controls to match that gameweek
    const getMatchingGameweek =  (gameweeks, week) => {
        console.log("week:", week)
        let potentialGameweek;
        console.log("matching gameweeks:", gameweeks.filter(gw => parseInt(gw.week) == week))
        potentialGameweek =  gameweeks.filter(gw => parseInt(gw.week) == week)[0]
        if(potentialGameweek != null){
            console.log("match found:", potentialGameweek )

            setOpponentName(potentialGameweek.opponent)

            setSelectedPerformances(potentialGameweek.performances)
            console.log("setting performances", potentialGameweek.performances)
            return potentialGameweek
        }
        else{
            console.log("couldn't load week")
        }

    }


    //increment a useState object's given field
    const incrementValue = (object, objectSetter, fieldString, increment) => {
        const newObject = JSON.parse(JSON.stringify(object));//copy object
        newObject[fieldString] += increment//increment given field
        objectSetter(newObject)//replace original object
    }

    const handlePerformanceUpdate =  async (updatedPerformance, fields) => {
        //get matching performance
        const performancesCopy = JSON.parse(JSON.stringify(selectedPerformances))
        console.log("updated performance: ", updatedPerformance, "performancesCopy:", performancesCopy)
        console.log("filtered performance for update: ", performancesCopy.filter(p => p.player._id == updatedPerformance.player._id))
        let matchingPerformance = performancesCopy.filter(p => (p.player._id == updatedPerformance.player._id)).pop()

        console.log("matching performance pre update:", matchingPerformance)


        // if(matchingPerformance != null){
        //     matchingPerformance = updatedPerformance
        // }

        fields.forEach(f => {
            // console.log("field attempting modification:", f);
            if(f != 'playerName' && f != 'player')
            matchingPerformance[f] = updatedPerformance[f]
        })

        matchingPerformance.score = 0

        // console.log("matching performance post update:", matchingPerformance, performancesCopy)


        const saveResult = await setSelectedPerformances(performancesCopy)

        





    }

    const generateNewPerformances = async () => {
        const players  = await fetch(props.state.API_BASE + '/players', {
            method: "GET",
            headers: {
                "authorization" : `Bearer ${props.state.token}`,
            }
        }).then(res => res.json()).then().catch(e => console.log(e));
        console.log(players)

        setPlayers(players);
        
        const newPerformances = [];
        players.forEach(p => {
            const newPerformance = {
                
                player: p._id, playerName: p.name, week: week, present: false,
                goalsScored: 0, assists: 0, goalsConceded: selectedGameweek.awayScore || 0,
                ownGoals: 0, bonusPoints: 0, points:0
            }
            newPerformances.push(newPerformance)
        })

        console.log("new performances:", newPerformances)
        const gameweekCopy = JSON.parse(JSON.stringify(selectedGameweek))
        gameweekCopy.performances.push(newPerformances)
        const gw = await setSelectedGameweek(gameweekCopy);
        const sp = await setSelectedPerformances(newPerformances)
        // handleGameweekSave()

    }


    console.log(props.visible)
    return(

        <div className={"gameweek-controls-container" + (props.visible? "" : " hidden")}>


            {/* <button onClick = {getGameWeeks}>Load Gameweek Data</button> */}




            <div className={"current-gameweek-container " + (allGameweeks.length > 0 ? "" : " hidden ")}>

                {/* <label htmlFor="">{`Gameweek: ${week}` }</label>

            
                <input type="number" name="" id="" value={week} min={0} max={10} 
                    onChange={e => {
                        setWeek(e.target.value);
                        setSelectedGameweek(getMatchingGameweek(allGameweeks, e.target.value))
                    }    
                    }/> */}

                <NumberPicker value={week}
                              labelText={"Gameweek"}
                              increase={() => {if (parseInt(week) < parseInt(setup.latestWeek)){setWeek(week+1); setSelectedGameweek(getMatchingGameweek(allGameweeks, week+1))}}}
                              decrease={() => {if (week > 0){setWeek(week -1); setSelectedGameweek(getMatchingGameweek(allGameweeks, week-1))}}}
                />

                

                <p>opponent name: {opponentName}</p>
                <label htmlFor="">Change Opponent Name:</label>
                <input type="text" name="" id="" 
                       onChange={e => setOpponentName(e.target.value)} />

                <NumberPicker value={selectedGameweek.homeScore}
                              labelText={"Home Goals:"}
                              increase={() => incrementValue(selectedGameweek, setSelectedGameweek, 'homeScore', 1)}
                              decrease={() => incrementValue(selectedGameweek, setSelectedGameweek, 'homeScore', -1)}
                              />
                              
                <NumberPicker value={selectedGameweek.awayScore}
                              labelText={"Away Goals:"}
                              increase={() => incrementValue(selectedGameweek, setSelectedGameweek, 'awayScore', 1)}
                              decrease={() => incrementValue(selectedGameweek, setSelectedGameweek, 'awayScore', -1)}
                              />

                <div className="gameweek-admin-api-buttons">
                    <button onClick={generateNewPerformances}>Generate New Performances</button>
                    <button onClick = {handleGameweekSave}>Save Gameweek</button>
                </div>

                <div className="performances-container">
                    {   
                        selectedPerformances.map(p =>{return(
                            <PerformanceRow performance = {p} 
                                            handlePerformanceUpdate={handlePerformanceUpdate} 
                                            awayScore={awayScore} 
                                            key={p._id} />
                        )})
                    }     
  
                </div>
       


                

            </div>
            




        </div>
    )
}

export default GameweekControls;