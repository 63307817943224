import { useState } from "react"

function Checkbox(props){
    return(
        <div className="Checkbox" onClick={() => props.updateVal(!props.val)}>
            <label htmlFor="">{props.labelText}</label>
            <p className={props.val ? "player-present" : "player-absent"}>{(props.val ? "Present" : "Absent")}</p>
        </div>
    )
}

export default Checkbox