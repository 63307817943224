import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './TeamViewer.css'
import './Admin.css'

import PlayerCard from './PlayerCard'
import StarPlayerCard from './StarPlayerCard'
import NumberPicker from './admin-components/NumberPicker'
import pitchBg from './pitch-compressed.jpg'
import SquadView from './SquadView'
import MiniTeamViewer from './MiniTeamViewer';
import Header from './Header'


const LATEST_WEEK = "3"
const MAX_TEAM_SIZE = 5;



function Team(){
    const location = useLocation();
    const state = location.state;

    const navigate = useNavigate();


    const setup = state.setup;

    const [allPlayers, setPlayers] = useState([])
    const [selectedWeek, setWeek] = useState(state.setup.latestWeek)

    const [performances, updatePerformances] = useState([])
    const [teamContent, setTeamContent] = useState([])
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [isTeamLoaded, setTeamLoaded] = useState(false)
    const [verifiedUsername, setVerifiedUsername] = useState("")
    const [role, setRole] = useState("user")
    // const [setup, setSetup] = useState(state.setup)
    const [fetchedWeeks, setFetchedWeeks] = useState([])
    const [allGameweeks, setAllGameweeks] = useState([])
    const[selectedGameweek, setSelectedGameweek] = useState({opponent:"data not loaded"})
    const [leaderboardSelections, setLeaderboardSelections] = useState([])
    const [allPlayersFilter, setAllPlayersFilter] = useState({filter: [],
                                                        filterName: "ALL",
                                                              allowAll: true})
    const [miniTeam, setMiniTeam] = useState({})
    const [miniTeamHidden, setMiniTeamHidden] = useState(true);

    useEffect( () => {
        //redirect to login if no login session detected
        if(!location.state.setup){
            window.alert("renavigating")

            navigate('/', {})
            return
        }

        //retrieve game rules and logged-in player's teams
        else{
            // getSetup()
            getPlayers()
            getTeam()
            getGameWeeks();
            document.addEventListener("click", () => console.log(
                state, selectedWeek, selectedGameweek
            ))
        }

    }, [])

    useEffect( () => {
        // if(setup.setupLoaded){
        //     setWeek(parseInt(setup.latestWeek));
        // }
    }, [setup])

    useEffect(() =>{
        if(allGameweeks.length > 0){
            setSelectedGameweek(getMatchingGameweek(allGameweeks, selectedWeek))
        }
        getWeekPerformances(selectedWeek)
        setLeaderboardSelections([])
    }, [selectedWeek])
    // const selections = props.selections



    // if (teamContent.length > 0) console.log("team viewer props recieved: ", teamContent)

    function getCurrentSelection(){
        const matchingSelections = teamContent.filter(s => parseInt(s.week) == parseInt(selectedWeek))
        // console.log("Content!", teamContent)
        // console.log("Matching selections", matchingSelections)
        if(matchingSelections.length > 0){
            return matchingSelections[0]
        }
        else {
            // console.log("no matching selection")
            return [];
        }
    }
    
    //store the game rules
    const getSetup = async() => {
        // // console.log("attempting to get game setup with token:", state.token)
        // const tokenString = `Bearer ${state.token}`
        // const setup  = await fetch(state.API_BASE + '/gameSetup', {
        //     method: "GET",
        //     headers: {
        //         "authorization" : tokenString
        //     }
        // }).then(res => res.json()).then(res => {setSetup({...res, setupLoaded: true})}).catch(e => console.log(e));
        
        // return setup;
    }

    //store the logged-in player's team selections
    const getTeam = async () =>{
        // console.log("attempting to get team with token:", state.token)
        const tokenString = `Bearer ${state.token}`
        const team  = await fetch(state.API_BASE + '/teams', {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json()).then(res => {setTeamContent(res);setTeamLoaded(true);}).catch(e => console.log(e));
    }


    const getGameWeeks = async() => {
        const response  = await fetch(state.API_BASE + '/gameweeks', {
            method: "GET",
            headers: {
                "authorization" : `Bearer ${state.token}`,
            }
        }).then(res => res.json()).then(res => {
            res.forEach(gw => {gw.performances.forEach(p => {p.playerName = p.player.name; return p}); return gw});
            return res;
        }).catch(e => console.log(e));

         setAllGameweeks(response)
        // console.log("all gameweeks updated:", response)
         setSelectedGameweek(getMatchingGameweek(response, selectedWeek))


        // console.log(selectedGameweek)

    }

    //gets the gameweek corresponding to current week, otherwise returns a valid gameweek and sets
    //controls to match that gameweek
    const getMatchingGameweek =  (gameweeks, week) => {
        // console.log("week:", week)
        let potentialGameweek;
        // console.log("matching gameweeks:", gameweeks.filter(gw => parseInt(gw.week) == week))
        potentialGameweek =  gameweeks.filter(gw => parseInt(gw.week) == week)[0]
        if(potentialGameweek != null){
            // console.log("match found:", potentialGameweek )

            // setOpponentName(potentialGameweek.opponent)

            // setSelectedPerformances(potentialGameweek.performances)
            // console.log("setting performances", potentialGameweek.performances)
            return potentialGameweek
        }
        else{
            window.alert("couldn't gameweek data, please refresh")
        }

    }

    const incrementValue = (getter, setter, inc, min, max, isString) => {
        // console.log(`inc ${inc}, getter: ${getter}`)
        let val = parseInt(getter) + parseInt(inc)
        // console.log("VAL:", val)
        if(val > max) val = max
        if(val < min) val = min

        // console.log(val)
        setter(isString ? "" + val : val)
    }

    //add a player to a week's selection on the front-end
    const handleAddPlayer = (player) => {
        if(teamContent.length == 0){
            // console.log("NO SELECTIONS YET")
            // const newSelection = {
            //     week: setup.latestWeek,
            //     players: [player._id],
            //     captain: player._id,
            //     username: state.username
            // }
            // setWeek(setup.latestWeek)
            // teamContent.push(newSelection)
            // WE WANT TO HANDLE WHEN THEY HAVE NO SELECTIONS,
            // POTENTIALLY SAVING AN EMPTY SELECTION TO MONGODB AND RECCALLING GET TEAMS, 
            // OR GENERATING A SELECTION object THAT IS LATER RESTRUCTURED SERVER-SIDE WHEN THEY SAVE TEAM
        }
        else{
            const currentSelection = getCurrentSelection()
            const currentTeam = getCurrentSelection().players || []
            if(currentTeam.length >= setup.maxPlayers){
                window.alert(`no more than ${setup.maxPlayers} players allowed in a squad`);
                return
            }
            let filteredPlayers = currentSelection.players.filter(p => p._id === player._id);
            if(filteredPlayers.length > 0){
                window.alert(`${player.name} is already in your team`);
                return;
            }

            const selectionsCopy = [...teamContent]
            currentSelection.players.push(player)
            if(currentTeam.length <= 1){
                currentSelection.captain = player._id
            }
            setTeamContent(selectionsCopy)
            // console.log("updated provisional selection:", selectionsCopy)

        }
    }

 

    //remove a player from a week's selection on the front-end model
    const handleRemovePlayer = (player) => {
        const currentSelection = getCurrentSelection();
        //check if team is empty
        // console.log("original player list:", currentSelection.players)
        const originalLength = currentSelection.players.length
        if (originalLength == 0){
            window.alert("can't remove player from empty team")
            return
        }

        //filter out our player
        const filteredTeam = currentSelection.players.filter(p => {
            // console.log("comparison", p._id != player._id);
            return p._id != player._id})
        // console.log("filtered team for removal: ", filteredTeam)
        //if proposed player isn't in the team do nothing
        if(filteredTeam.length == originalLength){
            // console.log("player isn't in team")
            return
        }

        //update model to remove the player 
        let selectionsCopy = [...teamContent];
        selectionsCopy = selectionsCopy.map(s => {
            if (parseInt(s.week) == selectedWeek){
                //if captain removed promote a new one
                if(s.captain == player._id){
                    if(filteredTeam.length > 0){
                        //new captain assigned
                        s.captain = filteredTeam[0]._id
                    }
                    else{
                        //no captain assigned
                        s.captain = "";
                    }
                }
                //set new team omitting removed player
                s.players = filteredTeam
            }
            return s
        })
        setTeamContent(selectionsCopy)

    }

    //change current week's selected captain on the front-end model
    const handleCaptainPlayer = (player) => {
        const currentSelection = getCurrentSelection()
        //check player is in team
        if (currentSelection.players.map(p => p._id).includes(player._id)){

            let selectionsCopy = [...teamContent]
            selectionsCopy = selectionsCopy.map(s => {
                if(s.week == selectedWeek){
                    s.captain = player._id
                }
                return s;
            })
            setTeamContent(selectionsCopy)
        }
        else{
            window.alert("attempted captain isn't in team")
        }
    }

    //handle selection change options on the front-end model
    const handlePlayerSelected = (player, action) => {
        if(parseInt(selectedWeek) < parseInt(setup.latestWeek)){
            window.alert("can't modify a previous week");
            return
        }
        if(action == "add"){
            handleAddPlayer(player)
        }
        else if (action == "remove"){
            handleRemovePlayer(player)
        }
        else if (action == "captain"){
            handleCaptainPlayer(player)
        }
    }

    //fetches and stores performances from database for a given week
    const getWeekPerformances = async (week) => {
        // console.log("attempting to get performances selection for week", week)

        //Only fetch a week that hasn't already been fetched
        if(fetchedWeeks.includes(week)){
            // console.log("DO NOTHING")
            return
        }

        //update our log with new week and fetch it
        const newFetchedWeeks = [...fetchedWeeks]
        newFetchedWeeks.push(week)
        setFetchedWeeks(newFetchedWeeks)


        const tokenString = `Bearer ${state.token}`
        const result  = await fetch(state.API_BASE + `/performances/week/${week}`, {
            method: "GET",
            headers: {
                "authorization" : tokenString,
            }
        }).then(res => res.json())
        //   .then(res => {console.log("fetched performances:", res); return res})
          .then(res => {      const newPerformances = performances.concat(res);
                            //   console.log("new Performances:", newPerformances)
                              updatePerformances(newPerformances)})
          .catch(e => console.log(e));
    }


    //get the performance from front-end state corresponding to given player and week
    const getMatchingPerformance = (playerId, week) => {
        // console.log("Performances:", performances)
        const matchingPerformances = performances.filter(p => {return (p.player == playerId && p.week == week)})
        // console.log(`matching performances for ${playerId} on week ${week}:`, matchingPerformances)
        if(matchingPerformances.length < 1){
            return {
                _id: "64f59a850e78b30100e49f9e", player: "64f592d8da741df1fbbc9035",
                week:week, present:false,
                goalsScored:0, assists:0,
                goalsConceded:0, ownGoals:0,
                points:0,
                exists: false,
                bonusPoints:0, __v:0
            }
        }
        else return matchingPerformances[0];
  
    }

    //save an updated (or new) selection to the server
    const saveSelection = async () => {
        // console.log("attempting to save selection for week", selectedWeek)
        const uploadSelection = JSON.parse(JSON.stringify(getCurrentSelection()))
        uploadSelection.players = uploadSelection.players.map(p => p._id)
        // console.log("Upload SELECTION", uploadSelection);
        const tokenString = `Bearer ${state.token}`

        const players  = await fetch(state.API_BASE + '/teams/new', {
            method: "PUT",
            headers: {
                "authorization" : tokenString,
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                uploadSelection
            })
        }).then(res => res.json())
          .then(res => console.log(res))
          .then(window.alert("selection saved successfully!"))
          .catch(err => window.alert("error saving selection:", err));
    }

    //fetch and store all players in the club from the database
    const getPlayers = async () => {
        // console.log("attempting to get all club players:", state.token)
        const tokenString = `Bearer ${state.token}`
        const players  = await fetch(state.API_BASE + '/players', {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json()).catch(e => console.log(e));
        setPlayers(players);
    }

    const sortByName = (players) => {
            return players.sort(function(a,b){
                return ( (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1 )
            })
    }

    const sortByMessage = (players, week) => {
        return players.sort( function(a, b){
            let aPerformance = getMatchingPerformance(a._id, week);
            let bPerformance = getMatchingPerformance(b._id, week);

            console.log(aPerformance, bPerformance)
            if(aPerformance.bonusMessage != null) return -1;
            else return bPerformance.points - aPerformance.points
        })
}

    const sortByPoints = (players, week) => {
        return players.sort( function(a, b){
            let aPerformance = getMatchingPerformance(a._id, week);
            let bPerformance = getMatchingPerformance(b._id, week);
            if (aPerformance.exists != null) return 1;
            if(aPerformance.points == bPerformance.points){
                if(aPerformance.bonusMessage != null ) return -1;
            }

            if (aPerformance.bonusMessage != null){
                if(bPerformance.bonusMessage != null) return bPerformance.points - aPerformance.points
                else return 1;
            }
            else if (bPerformance.bonusMessage != null){
                return -1
            }

            return bPerformance.points - aPerformance.points
        })
    }


    const filterMidfielders = (p) => {
        return ["CDM", "CM", "CAM", "LM", "RM", "??"].includes(p.position.toUpperCase())
    }

    const filterDefenders = (p) => {
        return ["GK", "CB", "LB", "LWB", "RB", "RWB"].includes(p.position.toUpperCase())
    }

    const filterForwards = (p) => {
        return ["CF", "ST", "LW", "RW"].includes(p.position.toUpperCase())
    }

    const sortByPosition = (players) => {



        return players.sort(function(a,b){
            return ( (a.name > b.name) ? 1 : -1 )
        })
    }

    const getWeekSelections = async (week) => {
        const tokenString = `Bearer ${state.token}`
        const players  = await fetch(state.API_BASE + `/teams/week${week}`, {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json())
          .then(res=> {console.log("leaderboard updated", res); setLeaderboardSelections(res)})
          .then(res => console.log(res))
    }

    const getSeasonLeaderboard = async (week) => {
        const tokenString = `Bearer ${state.token}`
        const players  = await fetch(state.API_BASE + `/accounts/leaderboard`, {
            method: "GET",
            headers: {
                "authorization" : tokenString
            }
        }).then(res => res.json())
          .then(res=> setLeaderboardSelections(res))
          .then(res => console.log(res))
    }

    const viewFullLeaderboard = () => {
        navigate('/leaderboard', {state: {
            username: "",
            API_BASE: state.API_BASE
        }})
    }
    
    //if we have a team loaded display it....

    if (teamContent.length > 0){
        var selectedTeam;
        //find corresponding week selection
        const matchingSelections = teamContent.filter(s => {return (s.week == selectedWeek)} )
        if(matchingSelections.length > 0){
            // console.log(matchingSelections.length," teams found")
            selectedTeam = matchingSelections[0].players
        }
        //if it doesn't exist just return a valid team and update the slider accordingly
        else {
            console.log("selected WEEK:", selectedWeek)
            // console.log("NEW TEAM INITIATED")
            selectedTeam = []
            teamContent.push({
                week: selectedWeek,
                players: [],
                username: state.username
            })
        }
    return(
        <div className="TeamViewer">
            <div className="team-page-background">

                <img src={pitchBg} alt="wide image of turkey's football stadium" />
            </div>
            <button className={"admin-button " + (state.role =="admin"  ? "" : " hidden ")} onClick={() => {
                const stateData = {token: state.token, API_BASE: state.API_BASE, setup: state.setup}
                navigate('/admin', {state: stateData})
                }}>Go To Admin Panel
            </button>
            {/* <label>{`week: ${selectedWeek} / ${setup.latestWeek}`}</label> */}

            <div className="team-info">
                <h2 className='team-heading'>{state.username}'s team</h2>

            {/* <h3 className='opponent-heading'>CSS Football vs. {selectedGameweek.opponent}</h3> */}
            {/* <p>Score: {teamContent.points}</p> */}
            </div>

            {/* <input type="number" value = {selectedWeek} min={1} max={parseInt(setup.latestWeek)} onChange={e => setWeek(e.target.value)}/> */}

            {/* <p>Score: {getCurrentSelection().score || "TBD"}</p> */}
            
            <div className="gameweek-info">

                
            </div>
            
            <div className="active-squad-container">
                <div className="active-squad-background">
                    {/* <img src={pitchBg} alt="" /> */}
                </div>
                <div className={"floating-options " + (parseInt(selectedWeek) >= parseInt(setup.latestWeek) && getCurrentSelection().players.length == 5 ? "current-week " : "prev-week ")}>
                    <p className='floating-points'><strong>{getCurrentSelection().points || "TBD"}</strong> pts</p>
                    <button className={"submit-selection-button " + (parseInt(selectedWeek) >= parseInt(setup.latestWeek) && getCurrentSelection().players.length == 5 ? "" : "hidden")} 
                        onClick={() => saveSelection()}>Save Team
                    </button>
                </div>

                <SquadView squad = {selectedTeam} setup = {setup} selectedWeek = {selectedWeek} 
                           updateSelection = {handlePlayerSelected} getCurrentSelection = {getCurrentSelection}
                           getMatchingPerformance = {getMatchingPerformance}
                ></SquadView>
            



            </div>

            

            <NumberPicker 
                    className = "large"
                    value={"Gameweek " + selectedWeek.toString()  + "/" + setup.latestWeek.toString()} 
                    increase={() => incrementValue(parseInt(selectedWeek), setWeek,  1, 0, parseInt(setup.latestWeek) , true)}
                    decrease={() => incrementValue(parseInt(selectedWeek), setWeek, -1, 0, parseInt(setup.latestWeek) , true)}>
            </NumberPicker>
            
            {/* {(selectedGameweek.homeScore == null ? <h3 className='opponent-heading'>CSS Football vs. {selectedGameweek.opponent}</h3> 
                                                 : <h3 className='opponent-heading'>CSS Football {selectedGameweek.homeScore} - {selectedGameweek.awayScore} {selectedGameweek.opponent}</h3> )
            } */}

            <div className='score-line'>
                <div className="box left">
                <p className="homename">CSS Football</p>

                </div>
                <div className="box centre">
                <p className="gameweek-score">{selectedGameweek.homeScore} - {selectedGameweek.awayScore}</p>

                </div>
                <div className="box right">
                <p className="awayname">{selectedGameweek.opponent}</p>

                </div>
            </div>

            {/* <button className={"api-button submit-selection-button " + (selectedWeek >= setup.latestWeek && getCurrentSelection().players.length == 5 ? "" : "hidden")} 
                        onClick={() => saveSelection()}>Save Selection
            </button> */}
            {/* <div className="active-squad-container">
                <div className="active-squad-background">
                    <img src={pitchBg} alt="" />
                </div>


            {
                selectedTeam.map(p => 
                {
                    // console.log("captains:",p.name, p._id, getCurrentSelection().captain)
                return(
                    <PlayerCard 
                        setup = {setup}
                        performance = {getMatchingPerformance(p._id, selectedWeek)}
                        updateSelection = {handlePlayerSelected}
                        player = {p} key={p._id}
                        isCaptain = {getCurrentSelection().captain == p._id}
                        extraClasses = {" remove "
                                         + (getCurrentSelection().captain == p._id ? " captain " : "")
                                         + (selectedWeek >= setup.latestWeek ? " modifiable " : " unmodifiable ")
                        }

                        removable = {true}
                    />
                )
                }
            )  
            }

                <button className={"api-button submit-selection-button " + (selectedWeek >= setup.latestWeek && getCurrentSelection().players.length == 5 ? "" : "hidden")} 
                        onClick={() => saveSelection()}>Save Selection
                </button>

            </div> */}



            {/* <p><strong>{getCurrentSelection().points || "TBD"}</strong> pts</p> */}

            <div className="current-team-controls">
                {/* <button className={"api-button load-scores-button " + (selectedWeek < setup.latestWeek ? "" : "hidden")} 
                        onClick={() => getWeekPerformances(selectedWeek)}>Load Scores
                </button> */}
                <p className={( ((getCurrentSelection().players.length < 5) && (parseInt(selectedWeek) >= parseInt(setup.latestWeek))) ? "" : "hidden")}>Add 5 players from the Squad below</p>

            </div>

            <div>
                
            <div className={"view-leaderboard-button api-button " + (parseInt(selectedWeek) >= parseInt(setup.latestWeek) ? "hidden" : "")}
                 onClick={() => getWeekSelections(selectedWeek)}>
                View Week {selectedWeek} Leaderboard
            </div>
            <button className="view-full-leaderboard-button" onClick={() => viewFullLeaderboard()}><p>Visit full Season Leaderboard <span className='arrow'>{"\u2192"}</span></p></button> 

            </div>



            <div className = {"table-container " + (leaderboardSelections.length < 1 ? "hidden" : "")}>
            <p>click on a username to view their team</p>
            {/* <p>Note: Weeks 0 and 1 do not contribute to Season total score</p> */}

            {/* <details>
                <summary>Why?</summary>
                because like 60% of the team is new, and also Philip's performance gave us all like 30 points and he didn't even pick himself for some reason
            </details> */}
            <table className='team-page-weekly-leaderboard'>
            <thead>
                <tr><th>Rank</th><th>Username</th><th>Week Score</th></tr>
            </thead>
            <tbody>
            {
                leaderboardSelections
                .filter(ls => ls.points != 0)
                .sort((a,b) => {return b.points - a.points})
                .map((ls, i) => {
                    return(
                        <tr className={(ls.username == state.username ? "clickable highlighted-user" : "clickable")} key={ls._id}
                            onClick={async () => {await setMiniTeam(ls); await setMiniTeamHidden(false)}}>
                        <td className='rank' width = "10%">{i+1}</td>
                        <td width = "70%">{ls.username}</td>
                        <td width = "20%">{ls.points} </td>
                        </tr>

                    )
                })
            }
            </tbody>
            </table>
            <MiniTeamViewer players={allPlayers} setup={setup} selection={miniTeam} hidden={miniTeamHidden} setHidden={setMiniTeamHidden}/>
            </div>



            {/* <button onClick={() => getPlayers()}>Get All Players</button> */}

            <div className="all-players-section">
                {/* <h2>All Squad Players:</h2>
                <div className="all-players-container">
                    {sortByName([...allPlayers]).map(p => 
                        (
                            <PlayerCard
                                setup = {setup}
                                performance = {getMatchingPerformance(p._id, selectedWeek)}
                                updateSelection = {handlePlayerSelected}
                                extraClasses = {" add "
                                                + (selectedWeek >= setup.latestWeek ? " modifiable " : " unmodifiable ")}
                                player = {p} key={p._id} id={p._id}
                                isCaptain = {false}
                            />
                        )
                    )
                }
                </div> */}
            {( parseInt(selectedWeek) < parseInt(setup.latestWeek) ) && allPlayers && (allPlayers.length > 0) ? 
            <div className="star-players-container">
                <h2>Top Performances</h2>
                <div className="star-players">

                {sortByPoints([...allPlayers], selectedWeek).filter((p, i, arr) => {
                    let perf = getMatchingPerformance(p._id, selectedWeek);
                    if (perf == null) return false;
                    if (perf.bonusMessage!= null){
                        return true;
                    }
                    if(arr.length > 5 && perf.points >= getMatchingPerformance(arr[5], selectedGameweek).points){
                        return true
                    }
                    return false
                }).slice(0, 6).map(p => 
                    (
                        <StarPlayerCard
                            setup = {setup}
                            performance = {getMatchingPerformance(p._id, selectedWeek)} 
                            updateSelection = {handlePlayerSelected}
                            player = {p} key={p._id} id={p._id}
                            extraClasses = {" add star-player-card "
                                            + (parseInt(selectedWeek) >= parseInt(setup.latestWeek) ? " modifiable " : " unmodifiable ")}
                            buttonClasses = {" add "}
                            isCaptain = {false}

                        />
                    )
                )  
            }

                </div>

            </div> : ""
            }


                <div className="all-players-filters-container">
                    <button 
                    className={"filter-button " + (allPlayersFilter.filterName == "ALL" ?  " active-filter " : "")}
                    onClick=
                    {e => {   
                        //    if (allPlayersFilter.filterName == "ALL") e.target.classList.add("active-filter")

                            // e.target.classList.toggle("active-filter")
                            setAllPlayersFilter(({filter:[], 
                                                filterName:"ALL",
                                                allowAll: true}))
                                                }}>
                        ALL
                    </button> 
                    <button 
                    className={"filter-button " + (allPlayersFilter.filterName == "DEF" ?  " active-filter" : "")}
                    onClick=
                    {e => { 
                            console.log(allPlayersFilter.filterName)

                            // e.target.classList.toggle("active-filter")
                            setAllPlayersFilter(({
                                                filter:["GK", "CB", "LB", "RB"], 
                                                filterName: allPlayersFilter.filterName != "DEF" ? "DEF" : "ALL",
                                                allowAll: allPlayersFilter.filterName == "DEF"}))}
                                                }>
                        DEF
                    </button>
                    <button 
                    className={"filter-button " + (allPlayersFilter.filterName == "MID" ?  " active-filter" : "")}
                    onClick=
                    {e => {
                            console.log(allPlayersFilter.filterName)
                            // e.target.classList.toggle("active-filter")
                            setAllPlayersFilter(({filter:["CDM", "CM", "CAM", "LM", "RM", "??"],
                                                filterName: (allPlayersFilter.filterName != "MID") ? "MID" : "ALL",
                                                allowAll: allPlayersFilter.filterName == "MID"}))}
                                                }>
                        MID
                    </button>
                    <button
                    className={"filter-button " + (allPlayersFilter.filterName == "FWD" ?  " active-filter" : "")}
                    onClick=
                    {e => {
                            console.log(allPlayersFilter.filterName)

                            // e.target.classList.toggle("active-filter")
                            setAllPlayersFilter(({filter:["CF", "ST", "LW", "RW"],
                                                filterName: allPlayersFilter.filterName != "FWD" ? "FWD" : "ALL",
                                                allowAll: allPlayersFilter.filterName == "FWD"}))}
                                                }>
                        FWD
                    </button>                               
                </div>



                <div className="all-players-container">
                    {sortByName([...allPlayers]).filter( p => {
                        if(allPlayersFilter.allowAll){return true;} 
                        else { return allPlayersFilter.filter.includes(p.position.toUpperCase())}})
                                    
                        .map(p => 
                        (
                            <PlayerCard
                                setup = {setup}
                                performance = {getMatchingPerformance(p._id, selectedWeek)}
                                updateSelection = {handlePlayerSelected}
                                extraClasses = {" add "
                                                + (parseInt(selectedWeek) >= parseInt(setup.latestWeek) ? " modifiable " : " unmodifiable ")}
                                player = {p} key={p._id} id={p._id}
                                isCaptain = {false}
                            />
                        )
                    )
                }
                </div>

                {/* <h3>Defenders</h3>
                <div className="all-players-container">
                    {sortByName([...allPlayers]).filter(filterDefenders)
                                                .map(p => 
                        (
                            <PlayerCard
                                setup = {setup}
                                performance = {getMatchingPerformance(p._id, selectedWeek)}
                                updateSelection = {handlePlayerSelected}
                                extraClasses = {" add "
                                                + (selectedWeek >= setup.latestWeek ? " modifiable " : " unmodifiable ")}
                                player = {p} key={p._id} id={p._id}
                                isCaptain = {false}
                            />
                        )
                    )
                }
                </div>
                <h3>Midfielders</h3>
                <div className="all-players-container">
                    {sortByName([...allPlayers]).filter(filterMidfielders)
                                                .map(p => 
                        (
                            <PlayerCard
                                setup = {setup}
                                performance = {getMatchingPerformance(p._id, selectedWeek)}
                                updateSelection = {handlePlayerSelected}
                                extraClasses = {" add "
                                                + (selectedWeek >= setup.latestWeek ? " modifiable " : " unmodifiable ")}
                                player = {p} key={p._id} id={p._id}
                                isCaptain = {false}
                            />
                        )
                    )
                }
                </div>

                <h3>Forwards</h3>
                <div className="all-players-container">
                    {sortByName([...allPlayers]).filter(filterForwards)
                                                .map(p => 
                        (
                            <PlayerCard
                                setup = {setup}
                                performance = {getMatchingPerformance(p._id, selectedWeek)}
                                updateSelection = {handlePlayerSelected}
                                extraClasses = {" add "
                                                + (selectedWeek >= setup.latestWeek ? " modifiable " : " unmodifiable ")}
                                player = {p} key={p._id} id={p._id}
                                isCaptain = {false}
                            />
                        )
                    )
                }
                </div> */}

            </div>
        </div>
    )
    }

    ///...otherwise gear the interface for creating a team
    else {

        // console.log(state)
        return (
            <div>

            <button className={"admin-button " + (state.role =="admin"  ? "" : " hidden ")} onClick={() => {
                const stateData = {token: state.token, API_BASE: state.API_BASE, setup: state.setup}
                navigate('/admin', {state: stateData})
                }}>Go To Admin Panel
            </button>
            <p>Click a player from the roster to begin building your team...</p>

            {/* <button onClick={() => getPlayers()}>Get All Players</button> */}




            <div className="all-players-container">
                <h2>All Squad Players:</h2>
                {sortByName([...allPlayers]).map(p => 
                    (
                        <PlayerCard
                            setup = {setup}
                            performance = {getMatchingPerformance(p._id, selectedWeek)} 
                            updateSelection = {handlePlayerSelected}
                            player = {p} key={p._id} id={p._id}
                            extraClasses = {" add "
                                            + (parseInt(selectedWeek) >= parseInt(setup.latestWeek) ? " modifiable " : " unmodifiable ")}
                            buttonClasses = {" add "}
                            isCaptain = {false}

                        />
                    )
                )  
            }
            </div>
        </div>)
    }
}

export default Team;